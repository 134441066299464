.receipt-page .main-content {
  padding: 30px 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.receipt-page .redeem-instructions-container {
  border-radius: 30px;
  padding: 40px;
  box-shadow: 0 0 10px rgba(217, 217, 217, 0.8);
}
.receipt-page .code-file-btn-holder {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.receipt-page .code-file-btn-holder .code-file-btn {
  font-size: 14px;
  max-width: 250px;
  height: 40px;
  margin-bottom: 15px;
}
.receipt-page .create-account-txt {
  text-align: center;
}