.offer-type-nav-mobile {
  height: 40px;
  padding-top: 13px;
  width: 100%;
  background-color: var(--brand-color-1);
  align-items: flex-end;
  justify-content: space-evenly;
}
.offer-type-nav-mobile .nav-item {
  width: 25%;
}
.offer-type-nav-mobile .nav-link {
  padding: 8px 0;
  color: var(--brand-color-text-on-color-1);
  font-family: var(--brand-font-2);
  font-weight: 800;
  font-size: 14px;
  border-bottom: 4px solid transparent;
  text-align: center;
}
.offer-type-nav-mobile .nav-link.active {
  border-bottom: 4px solid var(--brand-color-2);
}

#home-page .offer-type-nav-desktop {
  margin-top: 40px;
  justify-content: center;
  box-shadow: 0 0 10px rgba(217, 217, 217, 0.8);
  border-radius: 10px;
  overflow: hidden;
}
#home-page .offer-type-nav-desktop .nav-item.right-border {
  border-right: 1px solid rgba(217, 217, 217, 0.8);
}
#home-page .offer-type-nav-desktop .nav-link {
  padding: 8px 60px;
  justify-content: center;
  display: flex;
  font-family: var(--brand-font-2);
  font-weight: 800;
  font-size: 16px;
  color: var(--brand-color-gray-1);
}
#home-page .offer-type-nav-desktop .nav-link.active {
  background-color: var(--brand-color-2);
  color: white;
}
#home-page .header-wrapper {
  height: 250px;
  background-color: var(--brand-color-1);
  color: var(--brand-color-text-on-color-1);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}
@media (min-width: 768px) {
  #home-page .header-wrapper {
    height: 300px;
  }
}
#home-page .header-wrapper .header-text {
  padding-left: 10px;
  padding-right: 10px;
  font-weight: bold;
  font-size: 24px;
}
@media (min-width: 768px) {
  #home-page .header-wrapper .header-text {
    margin-top: 0;
    font-size: 48px;
    margin-bottom: 20px;
  }
}
#home-page .header-wrapper .header-subtext {
  margin: 2px 15px 15px 15px;
  font-family: var(--brand-font-2);
  font-size: 16px;
}
@media (min-width: 768px) {
  #home-page .header-wrapper .header-subtext {
    max-width: 600px;
  }
}
#home-page .header-wrapper .learn-more-btn {
  background-color: var(--brand-color-2);
  margin-top: 7px;
  color: white;
  border-radius: 4px;
  font-family: var(--brand-font-2);
  font-weight: bold;
  font-size: 14px;
  padding: 3px 20px;
}
@media (min-width: 768px) {
  #home-page .header-wrapper .learn-more-btn {
    padding: 10px 60px;
  }
}
#home-page .offer-list {
  margin-left: 4%;
  margin-right: 4%;
  padding-top: 20px;
  display: flex;
  flex-wrap: wrap;
}
#home-page .offer-list .home-page-offer-card {
  margin-bottom: 20px;
}
#home-page .offer-list .home-page-offer-card.redeemed p, #home-page .offer-list .home-page-offer-card.redeemed span {
  text-decoration: line-through;
}
#home-page .offer-list .home-page-offer-card a {
  color: inherit;
}
#home-page .offer-list .home-page-offer-card a:hover {
  text-decoration: none;
}
#home-page .offer-list .home-page-offer-card .img-container {
  border-radius: 15px;
  border: 1px solid lightgray;
  height: 150px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
@media (min-width: 768px) {
  #home-page .offer-list .home-page-offer-card .img-container {
    height: 200px;
  }
}
#home-page .offer-list .home-page-offer-card .card-pill {
  margin-left: 10px;
  margin-top: 13px;
  position: absolute;
  border: 1px solid #D8D8D8;
  color: var(--brand-color-gray-1);
  padding: 2px 20px;
  text-align: center;
  border-radius: 10px;
  font-size: 12px;
  font-weight: 700;
  font-family: var(--brand-font-2);
  text-decoration: none !important;
  background-color: white;
  z-index: 1000;
}
#home-page .offer-list .home-page-offer-card .fave-icon {
  position: absolute;
  right: 0;
  margin-right: 30px;
  margin-left: 5px;
  font-size: 22px;
  color: white;
  text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
}
#home-page .offer-list .home-page-offer-card .captions {
  padding-left: 5px;
  padding-right: 5px;
}
#home-page .offer-list .home-page-offer-card .captions p {
  margin-bottom: 0;
}
#home-page .offer-list .home-page-offer-card .captions .first-line {
  display: flex;
  justify-content: space-between;
}
#home-page .offer-list .home-page-offer-card .captions .first-line .offer-name {
  margin-top: 7px;
  font-weight: bold;
  font-size: 16px;
}
@media (min-width: 768px) {
  #home-page .offer-list .home-page-offer-card .captions .first-line .offer-name {
    font-size: 18px;
    padding-right: 10px;
  }
}
#home-page .offer-list .home-page-offer-card .captions .second-line-txt {
  font-family: var(--brand-font-2);
  font-size: 14px;
  display: block;
}
@media (min-width: 768px) {
  #home-page .offer-list .home-page-offer-card .captions .second-line-txt {
    font-size: 16px;
  }
}