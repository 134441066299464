@media (min-width: 992px) {
  .login-page .auth-container {
    max-width: 25% !important;
  }
}

@media (min-width: 992px) {
  .auth-container {
    max-width: 40%;
  }
}
.auth-container .asteriskField {
  display: none;
}
.auth-container .header-text-1 {
  margin-bottom: 5px;
}
.auth-container .header-text-2 {
  margin-bottom: 20px;
}
.auth-container .alert-block ul {
  padding-left: 0;
}
.auth-container .alert-block li {
  list-style-type: none;
}
.auth-container .form-group label {
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
}
.auth-container .form-group label.checkbox-label {
  font-weight: normal;
  font-size: 13px;
  margin: 0;
}
.auth-container .form-group .form-control {
  border: 3px solid #D8D8D8;
  border-radius: 2px;
}
.auth-container .form-group .form-control:focus {
  box-shadow: 0 0 0 0.1rem rgba(0, 123, 255, 0.25);
}
.auth-container .form-group .form-control::placeholder {
  font-size: 14px;
  color: #D8D8D8;
}
.auth-container .form-group .form-control.no-border {
  border: none;
}
.auth-container .form-group .errorlist {
  color: #fa755a;
  list-style-type: none;
  padding: 5px;
  font-size: 14px;
}
.auth-container .login-link {
  display: block;
  text-align: center;
  color: var(--brand-color-gray-1);
  text-decoration: underline;
  margin-bottom: 15px;
  font-size: 14px;
}
.auth-container .main-btn {
  margin-bottom: 30px;
}