#subscribe-page .plan-box {
  cursor: pointer;
  display: flex;
  align-items: center;
  border-radius: 10px;
  border: 2px solid #D8D8D8;
  padding: 25px 15px;
  margin-bottom: 20px;
}
#subscribe-page .plan-box.selected {
  border-color: var(--brand-color-2);
}
#subscribe-page .plan-box .checkbox-col {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  border: 2px solid #D8D8D8;
  margin: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
}
#subscribe-page .plan-box .checkbox-col span {
  display: none;
  color: white;
  font-size: 12px;
}
#subscribe-page .plan-box .checkbox-col.selected {
  border-color: var(--brand-color-2);
  background-color: var(--brand-color-2);
}
#subscribe-page .plan-box .checkbox-col.selected span {
  display: block;
}
#subscribe-page .plan-box .plan-header {
  font-size: 18px;
  margin-bottom: 3px;
  font-weight: 700;
}
#subscribe-page .plan-box .plan-subheader {
  font-size: 14px;
  margin-bottom: 0;
}
#subscribe-page .plan-box .plan-subheader .discount {
  margin-left: 5px;
  font-weight: 800;
  color: var(--brand-color-2);
}
#subscribe-page .input-label {
  font-weight: bold;
  font-size: 16px;
  font-family: var(--brand-font-1);
  color: var(--brand-color-gray-1);
}
#subscribe-page .referral-section {
  margin-top: 24px;
}
#subscribe-page .referral-section .referral-wrapper {
  display: flex;
  border: 3px solid #D8D8D8;
  border-radius: 2px;
}
#subscribe-page .referral-section .referral-wrapper .form-control {
  border: none;
}
#subscribe-page .referral-section .referral-wrapper .form-control:focus {
  box-shadow: none;
}
#subscribe-page .referral-section .referral-wrapper .apply-btn {
  font-family: var(--brand-font-1);
  font-weight: 700;
  font-size: 16px;
  color: var(--brand-color-2);
}
#subscribe-page .referral-section .referral-msg {
  padding: 5px;
  margin-bottom: 0;
  font-size: 11px;
  font-weight: 700;
  font-family: var(--brand-font-1);
}
#subscribe-page .referral-section .referral-msg.success {
  color: var(--brand-color-2);
}
#subscribe-page .referral-section .referral-msg.failure {
  color: #fa755a;
}
#subscribe-page #payment-form {
  margin-top: 24px;
  width: 100%;
}
#subscribe-page #payment-form label {
  padding-top: 0;
}
#subscribe-page #payment-form .element-container {
  margin-bottom: 14px;
}
#subscribe-page #payment-form .element-container #card-element.StripeElement {
  box-sizing: border-box;
  padding: 10px 12px;
  border: 3px solid #D8D8D8;
  border-radius: 2px;
}
#subscribe-page #payment-form .element-container #card-element.StripeElement--invalid {
  border-color: #fa755a;
}
#subscribe-page #payment-form .element-container #card-element.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}
#subscribe-page #payment-form .card-error {
  color: #fa755a;
}
#subscribe-page .price-msg {
  font-family: var(--brand-font-2);
  font-size: 12px;
  font-weight: 700;
  text-align: center;
}
#subscribe-page .disclaimer-text {
  margin-top: 10px;
  font-family: var(--brand-font-2);
  font-size: 12px;
  text-align: center;
}
#subscribe-page .disclaimer-text a {
  color: unset;
  text-decoration: underline;
  font-weight: bold;
}
#subscribe-page #submit-btn {
  display: unset;
}
#subscribe-page #submit-btn.loading {
  display: flex;
}