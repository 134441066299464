@media (min-width: 992px) {
  .account-page .page-content-inner-container {
    max-width: 35%;
  }
}
.account-page .page-header-section {
  margin-bottom: 35px;
}
.account-page .wannago-card {
  padding-top: 20px;
}
.account-page .wannago-card .label-1 {
  font-weight: bold;
  font-size: 48px;
  margin-bottom: 0;
}
.account-page .wannago-card .label-2 {
  font-weight: bold;
  font-size: 24px;
}
.account-page .wannago-card .label-3 {
  font-weight: normal;
  font-size: 13px;
}
.account-page .explanation-text {
  margin-bottom: 30px;
}
.account-page .explanation-text a {
  color: var(--brand-color-gray-1);
  text-decoration: underline;
}