.email-collection-wrapper {
  display: flex;
  justify-content: center;
}

.email-collection {
  margin-left: 4%;
  margin-right: 4%;
  padding-top: 40px;
  padding-bottom: 65px;
  margin-bottom: 65px;
  max-width: 500px;
}
.email-collection .header-label {
  font-family: var(--brand-font-1);
  font-size: 18px;
  font-weight: 800;
  text-align: center;
}
.email-collection .body-text {
  text-align: center;
  font-size: 14px;
  font-family: var(--brand-font-2);
}
.email-collection .input-and-btn {
  display: flex;
}
.email-collection .input-and-btn input {
  height: 32px;
  margin-right: 16px;
  font-size: 13px;
}
.email-collection .input-and-btn button {
  height: 32px;
  font-size: 13px;
  max-width: 110px;
  font-family: var(--brand-font-2);
}
.email-collection .input-and-btn button .loader {
  font-size: 2px;
}
.email-collection .response-msg {
  font-family: var(--brand-font-2);
  font-size: 14px;
  margin-top: 5px;
  margin-bottom: 0;
  margin-left: 5px;
}
.email-collection .response-msg.success {
  color: var(--brand-color-2);
}
.email-collection .response-msg.error {
  color: #fa755a;
}