body {
  font-family: var(--brand-font-1);
  color: var(--brand-color-gray-1);
  background-color: var(--brand-color-1);
}
@media (min-width: 768px) {
  body {
    background-color: inherit;
  }
}

.body-container {
  min-height: 100vh;
  background-color: white;
}

.navbar {
  background-color: var(--brand-color-1);
  padding-top: 14px;
  padding-bottom: 14px;
}
.navbar.brand-iltb, .navbar.brand-tampa {
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
}
@supports (padding-top: env(safe-area-inset-top)) {
  .navbar {
    --safe-area-inset-top: env(safe-area-inset-top);
    padding-top: calc(14px + var(--safe-area-inset-top));
  }
}
@media (min-width: 768px) {
  .navbar {
    padding-left: 24px;
    padding-right: 24px;
  }
}
@media (min-width: 992px) {
  .navbar {
    padding-left: 40px;
    padding-right: 40px;
  }
}
.navbar .navbar-toggler {
  padding-left: 0;
}
.navbar .navbar-side {
  display: flex;
  width: 30%;
}
@media (max-width: 320px) {
  .navbar .navbar-side {
    width: 24%;
  }
}
@media (min-width: 992px) {
  .navbar .navbar-side {
    width: auto;
  }
}
.navbar .navbar-left-icon {
  color: var(--brand-color-nav-secondary);
  font-size: 28px;
  width: 20px;
}
.navbar .navbar-brand {
  margin: 0;
  padding: 0;
}
.navbar .navbar-brand img {
  height: 28px;
}
@media (min-width: 768px) {
  .navbar .navbar-brand img {
    height: 44px;
  }
}
.navbar .navbar-collapse {
  order: 1;
}
@media (min-width: 992px) {
  .navbar .navbar-collapse {
    order: 0;
  }
}
.navbar .nav-right-container {
  justify-content: flex-end;
  align-items: center;
}
.navbar .nav-right-container .nav-right-btn {
  background-color: var(--brand-color-2);
  color: white;
  border-radius: 4px;
  font-family: var(--brand-font-2);
  font-weight: bold;
  font-size: 14px;
  padding: 3px 7px;
}
@media (min-width: 768px) {
  .navbar .nav-right-container .nav-right-btn {
    padding: 6px 16px;
    font-size: 16px;
  }
}
.navbar .nav-right-container .nav-right-btn.account-btn {
  padding: 3px 12px;
  height: 29px;
}
@media (max-width: 320px) {
  .navbar .nav-right-container .nav-right-btn.account-btn {
    padding: 3px 4px;
    font-size: 12px;
  }
}
.navbar .navbar-nav {
  padding-top: 10px;
}
@media (min-width: 992px) {
  .navbar .navbar-nav {
    padding-top: 0;
    padding-right: 10px;
    width: 100%;
    justify-content: flex-end;
  }
}
.navbar .navbar-nav .nav-link {
  text-align: center;
  color: var(--brand-color-nav-secondary);
  font-size: 18px;
  font-weight: bold;
  padding: 10px;
  font-family: var(--brand-font-2) !important;
}
@media (min-width: 992px) {
  .navbar .navbar-nav .nav-link {
    font-size: 16px;
    padding-left: 15px;
    padding-right: 15px;
  }
}
.navbar .lower-nav {
  width: 100%;
}

/***************************
  Common Stuff
***************************/
.page-content-container {
  margin-top: 68px;
  height: 100%;
}
@supports (padding-top: env(safe-area-inset-top)) {
  .page-content-container {
    --safe-area-inset-top: env(safe-area-inset-top);
    margin-top: calc(68px + var(--safe-area-inset-top));
  }
}
.page-content-container .page-content-inner-container {
  padding: 30px 25px;
}
@media (min-width: 992px) {
  .page-content-container .page-content-inner-container {
    max-width: 35%;
  }
}

.page-header-section .page-header-title {
  text-align: center;
  font-weight: bold;
  font-size: 24px;
}
.page-header-section .page-header-text {
  text-align: center;
  font-size: 14px;
  font-weight: 500;
}

.wannago-form label {
  font-weight: bold;
  font-size: 16px;
}
.wannago-form .asteriskField {
  display: none;
}

.wannago-link {
  font-weight: bold;
  color: var(--brand-color-gray-1);
  text-decoration: underline;
}

.wannago-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50px;
  border-radius: 4px;
  font-weight: bold;
  font-size: 18px;
}
.wannago-btn.primary {
  background: var(--brand-color-2);
  color: white;
}
.wannago-btn.secondary {
  background: #F7F2F2;
  color: var(--brand-color-1);
}
.wannago-btn.loading {
  background: var(--brand-color-2) !important;
}

.wannago-checkbox {
  min-height: 20px;
  min-width: 20px;
  margin-right: 15px;
}

.alert-modal.show {
  display: flex !important;
  justify-content: center;
  align-items: center;
}
.alert-modal.show .modal-dialog {
  width: 85%;
  max-width: 300px;
}
.alert-modal.show .modal-content {
  border-radius: 10px;
}
.alert-modal.show .modal-content .modal-header {
  justify-content: center;
  border: none;
  padding-top: 1.5rem;
  padding-bottom: 0;
}
.alert-modal.show .modal-content .modal-header .modal-title {
  font-family: var(--brand-font-2);
  font-weight: bold;
}
.alert-modal.show .modal-content .modal-body .modal-msg {
  font-family: var(--brand-font-2);
  font-size: 14px;
  margin-bottom: 0;
  text-align: center;
}
.alert-modal.show .modal-content .modal-footer {
  border: none;
  justify-content: center;
  padding: 0;
  height: 50px;
  margin-bottom: 10px;
}
.alert-modal.show .modal-content .modal-footer .modal-footer-btn {
  width: 50%;
  margin: 0;
  border-radius: 0;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: var(--brand-font-2);
  font-size: 18px;
}
.alert-modal.show .modal-content .modal-footer .modal-footer-btn.primary-btn {
  font-weight: 800;
}

.line-separator {
  height: 2px;
  background-color: #EFEFEF;
  margin: 25px;
}

.display-linebreak {
  white-space: pre-line;
}

.redeemed-overlay {
  background-color: var(--brand-color-1);
  opacity: 0.7;
  background-image: url("/static/images/checkmark-blue.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 40%;
  height: 100%;
  width: 100%;
}
@media (min-width: 576px) {
  .redeemed-overlay {
    background-size: 35%;
  }
}
.redeemed-overlay.in-card {
  border-radius: 15px;
  background-size: 35%;
}
@media (min-width: 576px) {
  .redeemed-overlay.in-card {
    background-size: 22%;
  }
}
@media (min-width: 768px) {
  .redeemed-overlay.in-card {
    background-size: 35%;
  }
}

.wannago-card {
  text-align: center;
  box-shadow: 0 0 10px #D9D9D9;
  border-radius: 15px;
  padding: 10px;
  margin-bottom: 20px;
}
.wannago-card.selected {
  color: white;
  background-color: var(--brand-color-2);
}

.sheen {
  position: relative;
  overflow: hidden;
}

.sheen:after {
  animation: sheen 1.5s 1.5s forwards;
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: linear-gradient(to bottom, transparent, rgba(255, 255, 255, 0.5) 50%, transparent);
  transform: rotateZ(60deg) translate(-1em, 4em);
}

@keyframes sheen {
  100% {
    transform: rotateZ(60deg) translate(0, -4em);
  }
}