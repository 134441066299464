#support-page {
  padding: 25px 10px;
  display: flex;
  flex-direction: column;
}
@media (min-width: 992px) {
  #support-page {
    flex-direction: row;
  }
}
@media (min-width: 992px) {
  #support-page .contact-section {
    order: 1;
    margin-top: 50px;
  }
}
#support-page .get-in-touch-section {
  display: flex;
  flex-direction: column;
  margin-top: 30px;
}
#support-page .get-in-touch-section .contact-row {
  display: flex;
  flex-direction: row;
  margin-bottom: 25px;
}
#support-page .get-in-touch-section .contact-row a:first-of-type {
  color: unset;
}
#support-page .get-in-touch-section .contact-row a:first-of-type i {
  margin-right: 15px;
  font-size: 30px;
}
#support-page .get-in-touch-section .contact-row span {
  font-size: 14px;
  font-family: var(--brand-font-2);
}
#support-page .get-in-touch-section .contact-row span a {
  color: #3B3B3B;
  text-decoration: underline;
}
#support-page .faqs-section {
  margin-top: 20px;
}
@media (min-width: 992px) {
  #support-page .faqs-section {
    margin-top: 50px;
    padding-right: 30px;
  }
}
#support-page .faqs-section .page-header-section {
  margin-bottom: 20px;
}
@media (min-width: 992px) {
  #support-page .faqs-section .page-header-section .page-header-title {
    text-align: left;
  }
}