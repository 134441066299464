#offer-nav-bookmark {
  padding: 0 8px 0 0;
  color: white;
  font-size: 24px;
}

@media (min-width: 768px) {
  #offer-detail-page {
    padding-top: 50px;
    margin-left: 4%;
    margin-right: 4%;
  }
}
#offer-detail-page .wrap {
  display: flex;
  flex-wrap: wrap;
}
#offer-detail-page .first-col {
  padding-left: 20px;
  padding-right: 20px;
}
@media (min-width: 768px) {
  #offer-detail-page .first-col {
    padding-right: 50px;
  }
}
#offer-detail-page .first-col .img-and-tabbar {
  margin-left: -20px;
  margin-right: -20px;
}
#offer-detail-page .first-col .carousel-img {
  height: 300px;
  object-fit: cover;
}
#offer-detail-page .first-col .empty-img-container {
  height: 300px;
  background-color: white;
  padding: 0;
  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
@media (min-width: 768px) {
  #offer-detail-page .first-col .empty-img-container {
    border: 1px solid lightgray;
  }
}
#offer-detail-page .first-col .header-section {
  margin-top: 30px;
}
#offer-detail-page .first-col .offer-name {
  font-weight: bold;
  font-size: 30px;
  line-height: 37px;
}
#offer-detail-page .first-col .offer-name.strikethrough {
  text-decoration: line-through;
}
#offer-detail-page .first-col .offer-header-detail {
  font-family: var(--brand-font-2);
  font-weight: normal;
  font-size: 18px;
  line-height: 25px;
  margin-bottom: 0;
  padding: 5px 2px;
}
#offer-detail-page .first-col .offer-header-detail i {
  margin-right: 10px;
}
#offer-detail-page .first-col .main-content-section {
  margin-top: 40px;
}
@media (min-width: 768px) {
  #offer-detail-page .first-col .main-content-section {
    margin-top: 33px;
  }
}
#offer-detail-page .first-col .main-content-section a {
  color: #0D5FB6;
}
#offer-detail-page .second-col .sticky-top {
  top: 100px;
}
#offer-detail-page .second-col .second-col-inner .map-container {
  height: 250px;
}
@media (min-width: 768px) {
  #offer-detail-page .second-col .second-col-inner .map-container {
    height: 300px;
  }
}
#offer-detail-page .second-col .second-col-inner .directions {
  margin-bottom: 90px;
  display: flex;
  align-items: center;
  padding-top: 3px;
}
#offer-detail-page .second-col .second-col-inner .directions i {
  font-size: 24px;
  margin-right: 7px;
}
#offer-detail-page .second-col .second-col-inner .directions button {
  padding-left: 0;
  font-family: var(--brand-font-2);
  font-size: 16px;
  color: var(--brand-color-gray-1);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
@media (min-width: 768px) {
  #offer-detail-page .second-col .second-col-inner .directions button {
    font-size: 18px;
  }
}
#offer-detail-page .tab-bar {
  background-color: #F4F4F4;
  padding: 10px;
}
@media (min-width: 768px) {
  #offer-detail-page .tab-bar {
    margin-top: 10px;
    background-color: transparent;
    padding-left: 0;
  }
}
#offer-detail-page .tab-bar .nav {
  justify-content: space-around;
  flex-wrap: nowrap;
  padding-left: 5px;
  padding-right: 5px;
}
@media (min-width: 768px) {
  #offer-detail-page .tab-bar .nav {
    padding: 0;
    flex-direction: column;
    align-items: flex-start;
  }
}
#offer-detail-page .tab-bar .nav-link {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: unset;
  text-align: center;
  padding-left: 5px;
  padding-right: 5px;
}
@media (min-width: 768px) {
  #offer-detail-page .tab-bar .nav-link {
    flex-direction: row;
    align-items: flex-start;
  }
}
#offer-detail-page .tab-bar .nav-link .fas {
  font-size: 18px;
  margin-bottom: 5px;
}
@media (min-width: 768px) {
  #offer-detail-page .tab-bar .nav-link .fas {
    margin-right: 18px;
    font-size: 22px;
  }
}
#offer-detail-page .tab-bar .nav-link span {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
#offer-detail-page .tab-bar .nav-link span.mobile-label {
  font-weight: bold;
  font-size: 12px;
}
#offer-detail-page .tab-bar .nav-link span.non-mobile-label {
  font-size: 18px;
  font-family: var(--brand-font-2);
}
#offer-detail-page .redeem-section {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 2000;
  width: 100%;
  background-color: #F4F4F4;
  padding: 15px;
  display: flex;
  justify-content: center;
}
@media (min-width: 768px) {
  #offer-detail-page .redeem-section {
    padding-left: 4%;
    justify-content: flex-start;
  }
}
#offer-detail-page .redeem-section .redeem-section-label {
  font-family: var(--brand-font-2);
  font-weight: bold;
  font-size: 18px;
}
@media (max-width: 320px) {
  #offer-detail-page .redeem-section .redeem-section-label {
    font-size: 16px;
  }
}
#offer-detail-page .redeem-section .redeem-section-sublabel {
  font-family: var(--brand-font-2);
  font-size: 14px;
}
@media (max-width: 320px) {
  #offer-detail-page .redeem-section .redeem-section-sublabel {
    font-size: 12px;
  }
}
#offer-detail-page .desktop-redeem-section {
  margin-top: 10px;
}
#offer-detail-page .desktop-redeem-section .labels {
  margin-right: 30px;
  padding-left: 5px;
}
#offer-detail-page .desktop-redeem-section .labels .redeem-section-label {
  font-family: var(--brand-font-2);
  font-weight: bold;
  font-size: 18px;
}
@media (max-width: 320px) {
  #offer-detail-page .desktop-redeem-section .labels .redeem-section-label {
    font-size: 16px;
  }
}
#offer-detail-page .desktop-redeem-section .labels .redeem-section-sublabel {
  font-family: var(--brand-font-2);
  font-size: 14px;
}
@media (max-width: 320px) {
  #offer-detail-page .desktop-redeem-section .labels .redeem-section-sublabel {
    font-size: 12px;
  }
}
#offer-detail-page .desktop-redeem-section button {
  max-height: 48px;
}
#offer-detail-page .redeem-btn {
  text-align: center;
  min-width: 42%;
  flex: 1;
  background: var(--brand-color-2);
  border-radius: 7px;
  color: white;
  font-weight: bold;
  font-size: 18px;
  font-family: var(--brand-font-2);
}
@media (max-width: 320px) {
  #offer-detail-page .redeem-btn {
    font-size: 14px;
  }
}
@media (min-width: 768px) {
  #offer-detail-page .redeem-btn {
    max-width: 260px;
    min-width: unset;
    font-size: 16px;
    height: unset;
    flex: unset;
    padding-left: 20px;
    padding-right: 20px;
  }
}
#offer-detail-page .redeem-btn:disabled {
  cursor: not-allowed;
  background-color: #C2C1C1;
}
#offer-detail-page .quantity-picker-component {
  width: 200px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
@media (min-width: 768px) {
  #offer-detail-page .quantity-picker-component {
    width: 170px;
  }
}
#offer-detail-page .quantity-picker-component .change-btn {
  margin-left: 10px;
  margin-right: 10px;
  border-radius: 50%;
  font-size: 30px;
  line-height: 1.2;
  padding: 0;
  height: 35px;
  width: 35px;
  background: var(--brand-color-2);
  color: white;
}
@media (min-width: 768px) {
  #offer-detail-page .quantity-picker-component .change-btn {
    background: white;
    color: var(--brand-color-2);
    border: 2px solid var(--brand-color-2);
  }
}
#offer-detail-page .quantity-picker-component .quantity-label {
  font-weight: bold;
  font-size: 22px;
  margin-left: 15px;
  margin-right: 15px;
}
@media (min-width: 768px) {
  #offer-detail-page .quantity-picker-component .quantity-label {
    margin-left: 0;
    margin-right: 0;
  }
}
#offer-detail-page .desktop-quantity-picker {
  align-items: center;
}
@media (min-width: 768px) {
  #offer-detail-page .desktop-quantity-picker {
    display: flex !important;
  }
}
#offer-detail-page .desktop-quantity-picker .section-header {
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 0;
}
#offer-detail-page .redeem-confirm-modal {
  visibility: hidden;
  position: fixed;
  z-index: 2000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);
  transition: opacity 10ms ease-in-out;
}
#offer-detail-page .redeem-confirm-modal.show {
  visibility: visible;
}
#offer-detail-page .redeem-confirm-modal-content, #offer-detail-page .mobile-quantity-picker {
  bottom: -500px;
  transition: bottom 150ms ease-in-out;
  position: fixed;
  left: 0;
  z-index: 2001;
  width: 100%;
  background-color: var(--brand-color-1);
  color: var(--brand-color-text-on-color-1);
  padding: 25px 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}
#offer-detail-page .redeem-confirm-modal-content.show, #offer-detail-page .mobile-quantity-picker.show {
  bottom: 0;
}
@media (min-width: 768px) {
  #offer-detail-page .redeem-confirm-modal-content, #offer-detail-page .mobile-quantity-picker {
    transform: translate(-50%, -50%);
    transition: top 200ms ease-in-out;
    top: -500px;
    left: 50%;
    bottom: unset !important;
    width: 40%;
    max-width: 600px;
    border-radius: 15px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.3);
  }
  #offer-detail-page .redeem-confirm-modal-content.show, #offer-detail-page .mobile-quantity-picker.show {
    top: 50%;
  }
}
#offer-detail-page .redeem-confirm-modal-content .close-btn, #offer-detail-page .mobile-quantity-picker .close-btn {
  background-color: transparent;
  border: none;
  align-self: flex-end;
  font-weight: bold;
  font-size: 18px;
  margin-top: -20px;
  margin-right: -25px;
  color: var(--brand-color-text-on-color-1);
}
#offer-detail-page .redeem-confirm-modal-content .section-header, #offer-detail-page .mobile-quantity-picker .section-header {
  font-weight: bold;
  font-size: 24px;
}
#offer-detail-page .redeem-confirm-modal-content .section-text, #offer-detail-page .mobile-quantity-picker .section-text {
  font-family: var(--brand-font-2);
  font-size: 16px;
  padding: 10px;
}
#offer-detail-page .redeem-confirm-modal-content .quantity-picker-holder, #offer-detail-page .mobile-quantity-picker .quantity-picker-holder {
  margin-top: 10px;
  margin-bottom: 25px;
}
#offer-detail-page .redeem-confirm-modal-content .main-btn, #offer-detail-page .mobile-quantity-picker .main-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  height: 48px;
  background: var(--brand-color-2);
  border-radius: 7px;
  color: white;
  font-weight: bold;
  font-size: 18px;
  font-family: var(--brand-font-2);
}
@media (min-width: 768px) {
  #offer-detail-page .redeem-confirm-modal-content .main-btn, #offer-detail-page .mobile-quantity-picker .main-btn {
    width: 250px;
  }
}