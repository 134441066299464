#survey-page {
  padding: 30px 25px;
  text-align: center;
}
#survey-page .header-section {
  margin-bottom: 35px;
}
#survey-page .header-section .header-title {
  color: var(--brand-color-gray-1);
  font-weight: bold;
  font-size: 24px;
  margin-bottom: 25px;
}
#survey-page .header-section .subheader-text {
  color: var(--brand-color-gray-1);
  font-family: var(--brand-font-2);
  font-size: 16px;
  margin-bottom: 0;
}
#survey-page .question-section {
  margin-bottom: 35px;
}
#survey-page .question-text {
  font-family: var(--brand-font-1);
  font-size: 18px;
  font-weight: 800;
  margin-bottom: 7px;
}
#survey-page .star-rating-container .dv-star-rating-star {
  font-size: 40px;
  margin-left: 6px;
  margin-right: 6px;
  margin-bottom: 0;
}
#survey-page .been-here-section .yes-no-btn {
  background-color: #C2C1C1;
  color: white;
  font-weight: bold;
  font-size: 18px;
  border-radius: 10px;
  margin-left: 15px;
  margin-right: 15px;
  padding: 8px 30px;
}
#survey-page .been-here-section .yes-no-btn.selected {
  background-color: var(--brand-color-2);
}
#survey-page .feedback-for-us-section #feedback-for-us-field {
  border: 3px solid #C2C1C1;
  border-radius: 5px;
  width: 100%;
  max-width: 450px;
  font-family: var(--brand-font-2);
  padding: 10px;
  font-size: 12px;
  font-weight: 300;
}
#survey-page .submit-btn-container {
  display: flex;
  justify-content: center;
}
#survey-page .submit-btn-container button.primary-action {
  font-family: var(--brand-font-2);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 450px;
  height: 50px;
  background: var(--brand-color-2);
  border-radius: 4px;
  color: white;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
}
#survey-page .submit-btn-container button.primary-action:disabled {
  background-color: #D8D8D8;
}