.gifting-page .plan-box {
  cursor: pointer;
  display: flex;
  align-items: center;
  border-radius: 10px;
  border: 2px solid #D8D8D8;
  padding: 25px 15px;
  margin-bottom: 20px;
}
.gifting-page .plan-box.selected {
  border-color: var(--brand-color-2);
}
.gifting-page .plan-box .checkbox-col {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  border: 2px solid #D8D8D8;
  margin: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.gifting-page .plan-box .checkbox-col span {
  display: none;
  color: white;
  font-size: 12px;
}
.gifting-page .plan-box .checkbox-col.selected {
  border-color: var(--brand-color-2);
  background-color: var(--brand-color-2);
}
.gifting-page .plan-box .checkbox-col.selected span {
  display: block;
}
.gifting-page .plan-box .plan-header {
  font-size: 18px;
  margin-bottom: 3px;
  font-weight: 700;
}
.gifting-page .plan-box .plan-subheader {
  font-size: 14px;
  margin-bottom: 0;
}
.gifting-page .plan-box .plan-subheader .discount {
  margin-left: 5px;
  font-weight: 800;
  color: var(--brand-color-2);
}

.gifting-complete-page .main-content {
  padding: 30px 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
}